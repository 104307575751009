<template>
  <div class="sidebar__wrapper">
    <a-lazy-hydrate when-visible>
      <a-global-time />
    </a-lazy-hydrate>

    <a-video-banner
      v-if="isAllNewsVideoBannerVisible"
      :video-banner-type="$options.consts.VIDEO_BANNER_TYPE.ALL_NEWS_SIDEBAR"
      class="sidebar__video-banner-container"
      key="sidebar-video-banner-1"
    />

    <a-video-banner
      v-if="isVideoBannerVisible"
      :video-banner-type="$options.consts.VIDEO_BANNER_TYPE.SIDEBAR"
      class="sidebar__video-banner-container"
      key="sidebar-video-banner-2"
    />

    <newsletter-widget class="sidebar__newsletter-widget" />

    <stock-daily-updates
      last-modified="stockDailyUpdates"
      class="sidebar__stock-daily-updates"
    />

    <a-ebook-with-transition
      v-if="isEbookVisible"
      :ebook-data="ebook"
      :mediaQueryMap="$options.consts.EBOOK_MEDIA_QUERY_MAP.SIDEBAR_EBOOK_MAP"
      :form-appearance="$options.consts.FORM_APPEARANCE.SLIDE_DOWN"
      :form-id="$options.consts.FORM_ID.EBOOK_SIDEBAR"
      class="sidebar__ebook"
    />

    <a-lazy-hydrate when-visible>
      <div
        class="sidebar__dummy-ebook"
        :class="$options.consts.EBOOK_DUMMY_CLASS"
        :data-ref="$options.consts.REFS.EBOOK_DUMMY_WRAPPER_SIDEBAR"
      />
    </a-lazy-hydrate>

    <top-forex-brokers
      v-if="isPageWithAds"
      class="sidebar__top-forex-brokers"
    />

    <premium-banner v-if="isPageWithAds" />

    <a-in-progress
      :in-progress="isSidebarContentRefreshInProgress"
      :align-spinner="$options.consts.SPINNER_ALIGNMENT.CENTER"
    >
      <must-read
        :last-modified="mustReadItemsLastModified"
        class="sidebar__must-read"
      />
    </a-in-progress>

    <a-lazy-hydrate when-visible>
      <a-banner
        v-if="isPageWithAds"
        class="sidebar__banner"
        :banner-settings="$options.consts.BANNER_SETTINGS.SIDEBAR.MIDDLE_BANNER"
      />
    </a-lazy-hydrate>
    <live-quotes
      :is-page-with-ads="isPageWithAds"
      class="sidebar__live-quotes"
    />

    <a-lazy-hydrate when-visible>
      <a-banner
        v-if="isPageWithAds"
        class="sidebar__banner"
        :banner-settings="
          $options.consts.BANNER_SETTINGS.SIDEBAR.BOTTOM_BANNER_1
        "
      />
    </a-lazy-hydrate>
    <a-lazy-hydrate when-visible>
      <a-banner
        v-if="isPageWithAds"
        class="sidebar__banner"
        :banner-settings="
          $options.consts.BANNER_SETTINGS.SIDEBAR.BOTTOM_BANNER_2
        "
      />
    </a-lazy-hydrate>
    <a-refresh-listener
      :articles="mustReadItemsForRefreshListener"
      :settings="$options.consts.AUTO_REFRESH_SETTINGS.SIDEBAR_MOST_VIEWED"
      @feed-update-required="refreshSidebarContent"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import AGlobalTime from './AGlobalTime'
import NewsletterWidget from './NewsletterWidget'
import ABanner from 'shared/ABanner'
import AVideoBanner from 'shared/AVideoBanner'
import ARefreshListener from 'shared/ARefreshListener'
import AInProgress, { SPINNER_ALIGNMENT } from 'shared/AInProgress'
import { AUTO_REFRESH_SETTINGS } from 'enums/article-auto-refresh'
import { BANNER_SETTINGS } from 'enums/banners/banner-settings'
import { EBOOK_MEDIA_QUERY_MAP, FORM_APPEARANCE } from 'shared/AEbook/enums'
import { PAGES_WITH_EBOOK } from 'enums/ebook'
import {
  SIDEBAR_PAGES_WITH_VIDEO_BANNER,
  VIDEO_BANNER_TYPE
} from 'enums/video-banner'
import { FORM_ID } from 'enums/form-id'
import { EBOOK_DUMMY_CLASS } from 'enums/onboarding/step-settings/ebook'
import { REFS } from 'enums/external-refs'
import { ROUTE_NAME } from 'enums/routes'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'

const PAGES_WITHOUT_ADS = [ROUTE_NAME.STOCKS, ROUTE_NAME.STOCKS_STOCK]

export default {
  name: 'ASidebar',
  components: {
    PremiumBanner: hydrateWhenVisible(() =>
      import('@/components/_layout/ASidebar/PremiumBanner')
    ),
    AVideoBanner,
    ABanner,
    AGlobalTime,
    NewsletterWidget,
    MustRead: hydrateWhenVisible(() => import('./MustRead'), {
      props: ['last-modified']
    }),
    StockDailyUpdates: hydrateWhenVisible(() => import('./StockDailyUpdates'), {
      props: ['last-modified']
    }),
    TopForexBrokers: hydrateWhenVisible(() => import('./TopForexBrokers')),
    LiveQuotes: hydrateWhenVisible(() => import('./LiveQuotes'), {
      props: ['is-page-with-ads']
    }),
    ARefreshListener,
    AInProgress,
    AEbookWithTransition: () => import('shared/AEbookWithTransition')
  },
  consts: {
    FORM_APPEARANCE,
    BANNER_SETTINGS,
    EBOOK_MEDIA_QUERY_MAP,
    VIDEO_BANNER_TYPE,
    EBOOK_DUMMY_CLASS,
    REFS,
    FORM_ID,
    AUTO_REFRESH_SETTINGS,
    SPINNER_ALIGNMENT
  },
  data() {
    return {
      actionsToRunOnRefresh: [this.requestSidebarContent],
      isSidebarContentRefreshInProgress: false
    }
  },
  computed: {
    ...mapGetters({
      ebook: 'ebooks/ebook',
      mustReadItemsLastModified: 'sidebar/mustReadItemsLastModified',
      mustReadItems: 'sidebar/mustReadItems'
    }),
    isPageWithAds() {
      return !PAGES_WITHOUT_ADS.includes(this.$route.name)
    },
    mustReadItemsForRefreshListener() {
      return this.mustReadItems.map(article => ({
        articleId: article.id,
        category: {
          slug: article.categorySlug
        }
      }))
    },
    isEbookVisible() {
      return PAGES_WITH_EBOOK.includes(this.$route.name)
    },
    isVideoBannerVisible() {
      return (
        this.isPageWithAds &&
        SIDEBAR_PAGES_WITH_VIDEO_BANNER.includes(this.$route.name)
      )
    },
    isAllNewsVideoBannerVisible() {
      return this.isPageWithAds && this.$route.name === ROUTE_NAME.ALL_NEWS
    }
  },
  watch: {
    '$route.path': {
      handler() {
        if (process.client) {
          this.refreshSidebarContent()
        }
      }
    }
  },
  methods: {
    ...mapActions({
      requestSidebarContent: 'sidebar/requestSidebarContent'
    }),
    /**
     * MustRead items works through the state and getters.
     * That's why we don't need ARefreshListener to update our items.
     * Instead, we listen to the event feed-update-required to initiate the
     * request. Getter values will be updated and re-rendered automatically.
     */
    async refreshSidebarContent() {
      try {
        this.isSidebarContentRefreshInProgress = true
        await Promise.all(this.actionsToRunOnRefresh.map(action => action()))
      } catch (err) {
        throw err
      } finally {
        this.isSidebarContentRefreshInProgress = false
      }
    }
  },
  mounted() {
    this.refreshSidebarContent()
  }
}
</script>

<style lang="scss">
.sidebar__wrapper {
  height: 100%;

  .sidebar__newsletter-widget,
  .sidebar__stock-daily-updates,
  .sidebar__ebook,
  .sidebar__must-read,
  .sidebar__top-forex-brokers,
  .sidebar__live-quotes,
  .sidebar__forex-education,
  .sidebar__find-forex-brokers {
    margin-top: 20px;
  }

  .sidebar__banner {
    margin: 40px 0;
  }

  .sidebar__video-banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: $sidebar-width-lg;
    min-height: 315px;

    @include sidebar-small-size {
      width: $sidebar-width-sm;
      min-height: 234px;
    }
  }

  .sidebar__section-title,
  .sidebar__section-content {
    padding: 0 20px;
  }
}
</style>
